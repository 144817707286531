// Required libs import
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Utility functions and constants
// ================================================================
import {
  SORT_OPTIONS,
  DATA_PROVIDERS,
  HISTORY_KEY,
  PROVIDER_KEY
} from '../const';
import { localStorageGet } from '../util';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';
import Dropdown from 'trendolizer-ui/build/Dropdown';
import Button from 'trendolizer-ui/build/Button';
import Popup from 'trendolizer-ui/build/Popup';
import HistoryBlock from './HistoryBlock';

const providerValues = Object.values(DATA_PROVIDERS);

// Map sorting options
// ================================================================
const sortDropdownOptions = Object.entries(SORT_OPTIONS).map(
  ([value, label]) => ({ label, value })
);

// Map providers options
// ================================================================
const providerDropdownOptions = Object.entries(DATA_PROVIDERS).map(
  ([label, value]) => ({ label, value })
);
const providersLength = providerDropdownOptions.length;
providerDropdownOptions.unshift({ label: 'All video sites', value: 'all' });
providerDropdownOptions.unshift({ label: 'All of Trendolizer', value: '' });

// Initial state shape
// ================================================================
const initState = {
  query: '',
  showHistory: false,
  sort: 'rate_likes',
  providers: ['']
};

// Component declaration
// ================================================================
export default class QueryForm extends React.Component {
  static blankParams = {
    query: '',
    providers: '',
    sort: ''
  };

  static propTypes = {
    loading: PT.bool,
    showDeselect: PT.bool,
    onReset: PT.func.isRequired,
    onSelect: PT.func.isRequired,
    onSubmit: PT.func.isRequired
  };

  state = initState;

  rootEl = React.createRef();

  componentDidMount() {
    document.documentElement.addEventListener('click', this.bodyClickHandler);
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener(
      'click',
      this.bodyClickHandler
    );
  }

  bodyClickHandler = (e) => {
    if (!this.rootEl.current.contains(e.target)) {
      this.hideHistory();
    }
  };

  onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit();
    } else {
      this.hideHistory();
    }
  };

  setFormValue = ({ name, value }) => this.setState({ [name]: value });

  setProvidersValue = ({ name, value }) => {
    let newValue = value;
    const lastValue = value[value.length - 1];
    if (lastValue === '') {
      newValue = [''];
    } else if (lastValue === 'all') {
      newValue = providerValues;
    } else {
      newValue = Object.keys(
        value.reduce((acc, item) => {
          if (item === '' || item === 'all') return acc;
          if (acc[item]) {
            delete acc[item];
          } else {
            acc[item] = true;
          }
          return acc;
        }, {})
      );
    }
    this.setState({ [name]: newValue });
  };

  onSubmit = (e) => {
    e && e.preventDefault();
    const { query, sort, providers } = this.state;
    const providersString = providers.join(',');
    const params = {
      search: query,
      sort
    };
    if (providersString.length) {
      params[PROVIDER_KEY] = `%${providersString}%`;
    }
    this.props.onSubmit(params);
  };

  onReset = (e) => {
    e.preventDefault();
    this.setState(initState);
    this.props.onReset();
  };

  hideHistory = () => {
    this.setState({ showHistory: false });
  };

  showHistory = (e) => {
    e.preventDefault();
    this.setState({ showHistory: true });
  };

  fillFormFromHistory = (request) => {
    this.setState({
      query: request.search,
      sort: request.sort,
      showHistory: false,
      providers: request[PROVIDER_KEY]
    });
  };

  providerValueRenderer = ({ label }, index) => {
    if (index === 0) {
      if (this.state.providers.length === providersLength) {
        return <span>All video sites</span>;
      }
      let more = this.state.providers.length - 1;
      return (
        <span>
          {label}
          {more > 0 ? ` and ${more} more` : null}
        </span>
      );
    }
    return null;
  };

  render() {
    const { loading, onSelect, showDeselect } = this.props;
    const { query, sort, providers, showHistory } = this.state;
    const history = localStorageGet(HISTORY_KEY) || [];
    return (
      <form
        onSubmit={this.onSubmit}
        onReset={this.onReset}
        className='QueryForm'
        ref={this.rootEl}
      >
        <div className='QueryForm-container'>
          <TextInput
            id='query'
            name='query'
            type='text'
            autocomplete='off'
            disabled={loading}
            onFocus={this.showHistory}
            className='QueryForm-textinput'
            placeholder='Search for...'
            onKeyDown={this.onInputKeyDown}
            value={query}
            onChange={this.setFormValue}
            icon='search'
          />
          <Button
            icon='history'
            onClick={this.showHistory}
            className='QueryForm-history-button'
          />
          <Dropdown
            id='providers'
            name='providers'
            disabled={loading}
            multi
            value={providers}
            className='QueryForm-providers'
            placeholder='Where to look...'
            removeSelected={false}
            options={providerDropdownOptions}
            valueRenderer={this.providerValueRenderer}
            onChange={this.setProvidersValue}
          />
          <Dropdown
            id='sort'
            name='sort'
            disabled={loading}
            value={sort}
            clearable={false}
            className='QueryForm-sorting'
            placeholder='Sort by...'
            options={sortDropdownOptions}
            onChange={this.setFormValue}
          />
          <Button
            id='submit'
            disabled={loading}
            className='QueryForm-submit'
            appearance='accent'
            type='submit'
            text='Search'
          />
          <Popup
            id='form-actions'
            appearance='solid'
            className='QueryForm-actions'
          >
            <Button text='Reset query' onClick={this.onReset} icon='block' />
            <Button text='Refresh results' type='submit' icon='refresh' />
            <Button
              text={showDeselect ? 'Clear selection' : 'Select all'}
              onClick={onSelect}
              icon='copy'
            />
          </Popup>
          {showHistory && history.length ? (
            <HistoryBlock
              className='QueryForm-history-dropdown'
              data={history}
              onClick={this.fillFormFromHistory}
            />
          ) : null}
        </div>
      </form>
    );
  }
}
